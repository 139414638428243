import { Link } from "react-router-dom"

import ReactOwlCarousel from "react-owl-carousel";
import { useQuery } from "@tanstack/react-query";
import ApiService from "../services/ApiService";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useSelector } from "react-redux";


function NewsEvents() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cmsData ,cmsLoading} = useSelector(state => state.Cms);


    const { data: eventData } = useQuery({
        queryKey: ['eventData'],
        queryFn: async () => {
            const response = await new ApiService().GET("/event?perPage=all");
            return response.data.data;
        },
    });


    return (<>

        {/* ////////// page title set /////////////////// */}
        <Helmet>
            <title>DG || News &amp; Events</title>
            <meta name="description" content="Helmet application" />
        </Helmet>
        {/* ///////// page title set End //////////////// */}


        {/* Inner-banner-start=====*/}
        {/* <section
            className="inner-banner"
            style={{ backgroundImage: `url("${news_events_bg}")` }}
        >
            <div className="container">
                <div className="content">
                    <h1>News &amp; Events</h1>
                    <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb m-0 justify-content-center">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                News &amp; Events
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section> */}
        <section className="hero">

            {cmsData?.data?.cms_banner?.newsEvents_page && <ReactOwlCarousel
                className='owl-theme'
                loop={true}
                margin={0}
                responsiveClass={true}
                nav={true}
                navText={['<','>']}
                dots={false}
                autoplay={true}
                autoplayTimeout={10000}

                responsive={{
                    0: {
                        items: 1,

                    },
                    768: {
                        items: 1,

                    },
                    1000: {
                        items: 1
                    }
                }}
            >
                {cmsData?.data?.cms_banner?.newsEvents_page?.map((item, index) => (<div key={index}>
                    <div className="item">
                        <div
                            className="hero-bg"
                            style={{ backgroundImage: `url(${item.image})`, height: "400px" }}
                        />
                    </div>
                </div>))}
            </ReactOwlCarousel>}

        </section >
        {/* Inner-banner-end=====*/}


        {/* News-event-start=====*/}
        <section className="upcoming-events p-top p-bottom">
            <div className="container">
                <div className="row">

                    {eventData?.map((item, index) => (<div key={index} className="col-md-4  mb-4">
                        <div className="evnt-bx">
                            <figure>
                                <img
                                    src={item.image}
                                    alt="event"
                                    className="img-fluid"
                                />
                            </figure>
                            <div className="details">
                                <small>{moment(item.start_date).format('dddd')}</small>
                                <b>{item.title}</b>
                                <Link to="/news-events-details" state={{ id: item.id }}>
                                    Know More <i className="fa-solid fa-angles-right" />
                                </Link>
                            </div>
                        </div>
                    </div>))}

                </div>
            </div>
        </section>
        {/* News-event-end=====*/}
    </>
    )
}

export default NewsEvents