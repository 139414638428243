import React, { useEffect } from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import { useQuery } from '@tanstack/react-query';
import ApiService from '../../services/ApiService';
import { useDispatch } from 'react-redux';
import { cmsActions } from '../../redux/reducers/Cms';


function Default() {

    const dispatch = useDispatch();

    const { data, isPending } = useQuery({
        queryKey: ['cmsData'],
        queryFn: async () => {
            const response = await new ApiService().GET("/cms-content");
            return response.data;
        },
    });


    useEffect(() => {
        if (data !== undefined) {
            // console.log("redux cms data ",data)
            dispatch(cmsActions.setCmsData(data));
        }
        dispatch(cmsActions.setCmsLoading(isPending));
    }, [data,isPending]);



    // useEffect(() => {
    //     // console.log("isPending ", isPending)
    //     dispatch(cmsActions.setCmsLoading(isPending));
    // }, [isPending])


    return (<>
        <Header />
        <Outlet />
        <Footer />
    </>)
}

export default Default