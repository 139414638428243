import { Link, NavLink } from "react-router-dom";
import Clock from 'react-live-clock';

import email_img from "../../assets/images/icon/email.svg";
import { useSelector } from "react-redux";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

function Header() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cmsData } = useSelector(state => state.Cms);


    return (<>
        {/* Header-start=====*/}
        <header id="header-section">
            <div className="top-bar-bg">
                <div className="container">
                    <div className="top-bar">
                        <div className="call">
                            <Link to={`mailto:${cmsData?.data?.social_links_email?.contact_email?.details}`}>
                                <img src={email_img} alt="email" />
                                {cmsData?.data?.social_links_email?.contact_email?.details}
                            </Link>

                            <ul className="live-clock">
                                {/* us,france,congo republic */}
                                <li>
                                    Baltimore <Clock format={'hh:mm A'} ticking={true} timezone={'America/New_York'} />
                                </li>
                                <li>
                                    Paris <Clock format={'hh:mm A'} ticking={true} timezone={'Europe/Paris'} />
                                </li>
                                <li>
                                    Kinshasa <Clock format={'hh:mm A'} ticking={true} timezone={'Africa/Kinshasa'} />
                                </li>
                            </ul>

                        </div>
                        <div className="t-social">
                            <Link to={cmsData?.data?.social_links_email?.fb_link?.details} target="_blank">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </Link>
                            <Link to={cmsData?.data?.social_links_email?.twitter_link?.details} target="_blank">
                                <FontAwesomeIcon icon={faXTwitter} />
                            </Link>
                            <Link to={cmsData?.data?.social_links_email?.gmail_link?.details} target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </Link>
                            <Link to={cmsData?.data?.social_links_email?.insra_link?.details} target="_blank">
                                <FontAwesomeIcon icon={faInstagram} />
                            </Link>
                            <Link to={cmsData?.data?.social_links_email?.youtube_link?.details} target="_blank">
                                <FontAwesomeIcon icon={faYoutube} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-bar">
                <div className="container">
                    <nav>
                        <div className="logo">
                            <NavLink to="/">
                                <img
                                    className="desk-logo"
                                    src={cmsData?.data?.setting?.header_logo?.resize_url}
                                    alt="logo"
                                />
                            </NavLink>
                        </div>
                        <ul>
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/books">Books</NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/bibliography">Bibliography</NavLink>
                            </li> */}
                            <li>
                                <NavLink to="/award-honors">Awards &amp; Honors</NavLink>
                            </li>
                            <li>
                                <NavLink to="/news-events">News &amp; Events</NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/blogs-articles">Blogs</NavLink>
                            </li> */}
                        </ul>
                        <div className="login-btn d-flex justify-content-end">
                            <NavLink to="/contact-me" className=" btn-primary">
                                Contact Me
                            </NavLink>
                        </div>
                        <div className="mobile-toggle ">
                            <span />
                            <span />
                            <span />
                        </div>
                    </nav>
                </div>
            </div>
        </header>
        {/* Header-end=====*/}
    </>
    )
}

export default Header