import ReactOwlCarousel from "react-owl-carousel";
import { useQuery } from "@tanstack/react-query";
import ApiService from "../services/ApiService";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";


import { Tab, Nav } from 'react-bootstrap';
import { useState } from "react";
import parse from 'html-react-parser';

function Bibliography() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { cmsData, cmsLoading } = useSelector((state) => state.Cms);

  const [activeTab, setActiveTab] = useState('tab-0');


  const { data: bibliographyData } = useQuery({
    queryKey: ['bibliographyData'],
    queryFn: async () => {
      const response = await new ApiService().GET("/bibliography");
      return response.data;
    },
  });

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {/* ////////// page title set /////////////////// */}
      <Helmet>
        <title>DG || Bibliography</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      {/* ///////// page title set End //////////////// */}

      {/* Inner-banner-start=====*/}
      <section className="hero">
        {cmsData?.data?.cms_banner?.bibliography_page && (
          <ReactOwlCarousel
            className="owl-theme"
            loop={true}
            margin={0}
            responsiveClass={true}
            nav={false}
            dots={false}
            autoplay={true}
            autoplayTimeout={5000}
            responsive={{
              0: {
                items: 1,
              },
              768: {
                items: 1,
              },
              1000: {
                items: 1,
              },
            }}
          >
            {cmsData?.data?.cms_banner?.bibliography_page?.map(
              (item, index) => (
                <div key={index}>
                  <div className="item">
                    <div
                      className="hero-bg"
                      style={{
                        backgroundImage: `url(${item.image})`,
                        height: "400px",
                      }}
                    />
                  </div>
                </div>
              )
            )}
          </ReactOwlCarousel>
        )}
      </section>
      {/* Inner-banner-end=====*/}


      {/* Bibliography-tab-start=====*/}
      <section className="bibliography-tab p-top p-bottom">
        <div className="container">
          <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
            <div className="tab-container d-flex align-items-start">

              <Nav variant="pills" className="me-3" aria-orientation="vertical">
                {bibliographyData?.data?.map((item, index) => (<Nav.Item key={index}>
                  <Nav.Link eventKey={`tab-${index}`}>{item.title}</Nav.Link>
                </Nav.Item>))}
              </Nav>

              <Tab.Content className="ps-4">
                {bibliographyData?.data?.map((item, index) => (<Tab.Pane eventKey={`tab-${index}`} key={index}>
                  {parse(item.description)}
                </Tab.Pane>))}
              </Tab.Content>

            </div>
          </Tab.Container>
        </div>
      </section>
      {/* Bibliography-tab-end=====*/}


    </>
  );
}

export default Bibliography;
