import Callnew from "../assets/images/icon/call-new.svg";
import Emailnew from "../assets/images/icon/email-new.svg";
import Location from "../assets/images/icon/location-new-one.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { Button, Form } from 'react-bootstrap';

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import ApiService from "../services/ApiService";
import Swal from 'sweetalert2'

const phoneRegExps = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Schema = yup.object({
  name: yup.string().trim().required("full name required"),
  email: yup.string().trim().email().required("email required"),
  // phone: yup.string().max(10, "phone length must be 10 characters").min(10, "phone length must be 10 characters").matches(phoneRegExps, 'phone number is not valid'),
  message: yup.string().required("message required")
});

function ContactUs() {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { cmsData } = useSelector(state => state.Cms);


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(Schema),
  });


  const onSubmit = async (data) => {
    // console.log(data)

    const response = await new ApiService().POST("/contact-query", data);

    if (response.data.status) {
      Swal.fire({
        title: (await response).data.msg,
        icon: "success"
      });
    }

    reset();
  }


  return (
    <>
      {/* Contact-us-page-start===*/}
      <section className="contact-us p-top p-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="contact">
                <h4>Contact</h4>
                <ul>
                  {/* <li>
                    <strong>Call Us</strong>
                    <Link to={`tel:${cmsData?.data?.contact_page?.phone}`}>
                      {" "}
                      <img
                        src={Callnew}
                        alt="icon"
                        className="img-fluid"
                      />{" "}
                      {cmsData?.data?.contact_page?.phone}
                    </Link>
                  </li> */}
                  <li>
                    <strong>Email</strong>
                    <Link to={`mailto:${cmsData?.data?.contact_page?.email}`}>
                      <img
                        src={Emailnew}
                        alt="icon"
                        className="img-fluid"
                      />{" "}
                      {cmsData?.data?.contact_page?.email}
                    </Link>
                  </li>
                  {/* <li>
                    <strong>Address</strong>
                    <Link to="#">
                      <img
                        src={Location}
                        alt="icon"
                        className="img-fluid"
                      />
                      {cmsData?.data?.contact_page?.address && parse(cmsData?.data?.contact_page?.address)}
                    </Link>
                  </li> */}

                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-0">
              <div>
                <div className="customize-form">
                  <h4 className="text-center">Do you have any Questions</h4>
                </div>
                <div className="form-row">
                  <Form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          {...register("name")}
                          isInvalid={!!errors.name} />

                        <Form.Control.Feedback type="invalid">
                          {errors.name?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-12">
                      <Form.Group>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          {...register("email")}
                          isInvalid={!!errors.email} />

                        <Form.Control.Feedback type="invalid">
                          {errors.email?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    {/* <div className="col-12">
                      <Form.Group>
                        <Form.Control
                          type="number" placeholder="Phone No"
                          {...register("phone")}
                          isInvalid={!!errors.phone} />

                        <Form.Control.Feedback type="invalid">
                          {errors.phone?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div> */}

                    <div className="col-12">
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          placeholder="Message"
                          style={{ height: '160px' }}
                          {...register("message")}
                          isInvalid={!!errors.message} />

                        <Form.Control.Feedback type="invalid">
                          {errors.message?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-12 mt-4">
                      <Button variant="primary" type="submit" className="col-12">
                        SEND ENQUIRY
                      </Button>
                    </div>

                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact-us-page-end===*/}
    </>
  );
}

export default ContactUs;
